import { ToastsV2 } from '@zola/zola-ui/src/components/ToastsV2';
import { connect } from 'react-redux';
import { toastsActions } from '@zola-helpers/client';

const mapStateToProps = state => ({
  toasts: state.toasts || [],
});

const mapDispatchToProps = dispatch => ({
  onRemoveToast: id => dispatch(toastsActions.hide(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ToastsV2);
